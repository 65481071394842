import React from "react";
import TimerCountDown from "../../template/Coin/CountDownTimer";
import UpcomingEventImg from "../../assets/img/daCrazy/upcoming_event.svg";
import medal_1 from "../../assets/img/daCrazy/medal_1.svg";
import medal_2 from "../../assets/img/daCrazy/medal_2.svg";
import medal_3 from "../../assets/img/daCrazy/medal_3.svg";
import fantasyLotto from "../../assets/img/daCrazy/fantasy lotto.svg";
import DaCrazyPoster from "../../assets/img/daCrazy/daCrazyPoster.svg";
import "./style.css";
const EventContainer = () => {
  // Header Component
  const LeaderboardHeader = () => {
    return (
      <div className="leaderboard-header">
        <div className="leaderboard-column">Rank</div>
        <div className="leaderboard-column">Name</div>
        <div className="leaderboard-column">$DaCrazy Token Hold</div>
      </div>
    );
  };

  // Data Row Component
  const LeaderboardRow = ({ rank, name, tokens }) => {
    // Determine which medal to display based on the rank
    const getMedalImage = (rank) => {
      switch (rank) {
        case 1:
          return medal_1;
        case 2:
          return medal_2;
        case 3:
          return medal_3;
        default:
          return null;
      }
    };

    return (
      <div className="leaderboard-row">
        <div className="leaderboard-column">
          {rank <= 3 ? (
            <img
              src={getMedalImage(rank)}
              alt={`Rank ${rank}`}
              className="rank-image"
            />
          ) : (
            <span>{rank}</span>
          )}
        </div>
        <div className="leaderboard-column">{name}</div>
        <div className="leaderboard-column">{tokens}</div>
      </div>
    );
  };

  // Main Leaderboard Component
  const Leaderboard = ({ data }) => {
    return (
      <div className="leaderboard-container">
        <LeaderboardHeader />
        {data.map((item, index) => (
          <LeaderboardRow
            key={index}
            rank={item.rank}
            name={item.name}
            tokens={item.tokens}
          />
        ))}
      </div>
    );
  };

  const Card = ({ imgSrc, heading, text1, text2, text3, text4 }) => {
    return (
      <div className="card">
        <img src={imgSrc} alt={heading} className="card-image" />
        <h3>{heading}</h3>
        <p>
          {text1 && <span>{text1}</span>}
          {text2 && <span>{text2}</span>}
          {text3 && <span>{text3}</span>}
          {text4 && <span>{text4}</span>}
        </p>
      </div>
    );
  };

  // Inner Component
  const InnerComponent = ({ logoSrc, text, buttonText }) => {
    return (
      <div className="inner-component">
        <div className="content">
          <img src={logoSrc} alt="Logo" className="logo" />
          <span>{text}</span>
        </div>
        <div className="action">
          <button className="btn more-btn black-btn">{buttonText}</button>
        </div>
      </div>
    );
  };

  const leaderboardData = [
    { rank: 1, name: "Alice", tokens: 1000 },
    { rank: 2, name: "Bob", tokens: 900 },
    { rank: 3, name: "Charlie", tokens: 850 },
    { rank: 4, name: "David", tokens: 800 },
    { rank: 5, name: "Eve", tokens: 750 },
    { rank: 6, name: "Frank", tokens: 700 },
    { rank: 7, name: "David", tokens: 800 },
    { rank: 8, name: "Eve", tokens: 750 },
    { rank: 9, name: "Frank", tokens: 700 },
    { rank: 10, name: "Frank", tokens: 700 },
    { rank: 11, name: "David", tokens: 800 },
    { rank: 12, name: "Eve", tokens: 750 },
    { rank: 13, name: "Frank", tokens: 700 },
  ];

  return (
    <div>
      <div style={{ marginTop: "200px" }}>
        <h1 className="main-heading">
          Upcoming <br />
          Power Slap Competition
          <br />
          October 2024
        </h1>
        <div style={{ maxWidth: "1280px", margin: "50px auto" }}>
          {/* <img src={UpcomingEventImg} alt="" /> */}
          <img src={DaCrazyPoster} alt="" />
        </div>
        <div
          style={{
            width: "fit-content",
            margin: "50px auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <TimerCountDown />
        </div>

        <h3 className="top10DaCrazyHeading">Top 10 Da Crazy Token Holders</h3>

        <h4 className="leaderboardHead">Leader Board</h4>
        <div style={{ padding: "32px" }}>
          <Leaderboard data={leaderboardData} />
        </div>
        <div className="responsive-row">
          <Card
            imgSrc={medal_1}
            heading="1st Prize"
            text1=" $1000 Da Crazy Tokens"
            text2="+ 1 Power Slap Ticket "
            text3="+ 1/1 Da Crazy Card "
            text4={"+ Get to meet Da Crazy Hawaiian!"}
          />
          <Card
            imgSrc={medal_2}
            heading="2nd Prize"
            text1=" $500 Da Crazy Tokens"
            text2="+ 1 Power Slap Ticket"
            text3="+ 1/1 Da Crazy Card "
          />
          <Card
            imgSrc={medal_3}
            heading="3rd Prize"
            text1=" $250 Da Crazy Tokens"
            text2="+ 1/1 Da Crazy Card "
          />
        </div>

        <div className="responsive-container">
          <InnerComponent
            logoSrc={fantasyLotto}
            text="Play the lottery and Win Ticket"
            buttonText="play lotto"
          />
          <InnerComponent
            logoSrc={fantasyLotto}
            text="Play the lottery and Win Trading Cards"
            buttonText="play lotto"
          />
          <InnerComponent
            logoSrc={fantasyLotto}
            text="Play the lottery and Win Tickets and Trading Cards"
            buttonText="play lotto"
          />
        </div>
      </div>
    </div>
  );
};

export default EventContainer;
